import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  BackendReponse,
  FrontendVoyageComment,
  Voyage,
  VoyageAnalysisRaw,
  VoyageComment,
  VoyageRaw,
  CompanyIdCharterer,
  VoyageSummary,
  PreferredDistance,
  VoyageAnomalies,
  FrontendVoyage,
  VoyageUpdate,
  VoyageTag,
} from 'src/types';

@Injectable({ providedIn: 'root' })
export class VoyageService {
  private scrollPosition = 0;
  siglarApiUrl = environment.siglarApi.url;

  constructor(private http: HttpClient) {}

  setScrollPosition(position: number) {
    this.scrollPosition = position;
  }

  getScrollPosition(): number {
    return this.scrollPosition;
  }

  // Load all voyage, set filter = notVerifiedOnly=true to get only unverified voyages.
  loadVoyages(filter = 'notVerifiedOnly=true') {
    const query = filter ? `?${filter}` : '';
    const voyagesEndpointUrl = new URL(`/api/v1/voyages${query}`, this.siglarApiUrl);
    return this.http.get<BackendReponse<VoyageRaw[]>>(voyagesEndpointUrl.href);
  }

  // Load all ship voyage
  loadShipVoyages(imo: number) {
    const voyagesEndpointUrl = new URL(`/api/v1/voyages/${imo}`, this.siglarApiUrl);
    return this.http.get<BackendReponse<Voyage[]>>(voyagesEndpointUrl.href);
  }

  loadVoyageList(filter: string) {
    const query = filter ? `?${filter}` : '';
    const voyagesEndpointUrl = new URL(`/api/v1/voyages/list${query}`, this.siglarApiUrl);
    return this.http.get<BackendReponse<VoyageRaw[]>>(voyagesEndpointUrl.href);
  }

  searchVoyage(filter: string) {
    const query = filter ? `?${filter}` : '';
    const voyagesEndpointUrl = new URL(`/api/v1/voyages/search${query}`, this.siglarApiUrl);
    return this.http.get<BackendReponse<FrontendVoyage[]>>(voyagesEndpointUrl.href);
  }

  loadVoyageDetails(uuid: string) {
    const voyageReportEndpointUrl = new URL(`/api/v1/voyages/${uuid}/analysis?version=2304`, this.siglarApiUrl);
    return this.http.get<BackendReponse<VoyageAnalysisRaw[]>>(voyageReportEndpointUrl.href);
  }

  loadVoyageAnomalies() {
    const voyagesEndpointUrl = new URL(`/api/v1/voyages/anomalies/summary/all`, this.siglarApiUrl);
    return this.http.get<{ data: VoyageAnomalies[] }>(voyagesEndpointUrl.href);
  }

  loadVoyageComments(uuid: string) {
    const voyageReportEndpointUrl = new URL(`/api/v1/voyages/${uuid}/comments`, this.siglarApiUrl);
    return this.http.get<{ data: VoyageComment[] }>(voyageReportEndpointUrl.href);
  }

  loadVoyageSummary(query: string) {
    const voyagesEndpointUrl = new URL(`/api/v1/voyages/analysis/summary${query}`, this.siglarApiUrl);
    return this.http.get<{ data: VoyageSummary }>(voyagesEndpointUrl.href);
  }

  createVoyage(voyage: Voyage) {
    const voyagesEndpointUrl = new URL('/api/v1/voyages', this.siglarApiUrl);
    return this.http.post<Voyage>(voyagesEndpointUrl.href, voyage);
  }

  updateVoyage(voyage: Voyage, uuid: string) {
    const voyagesEndpointUrl = new URL(`/api/v1/voyages/${uuid}`, this.siglarApiUrl);
    return this.http.put<Voyage>(voyagesEndpointUrl.href, voyage);
  }

  patchVoyage(voyage: Partial<Voyage>, uuid: string) {
    const voyagesEndpointUrl = new URL(`/api/v1/voyages/${uuid}`, this.siglarApiUrl);
    return this.http.patch<Voyage>(voyagesEndpointUrl.href, voyage);
  }

  assignVoyage(assignedTo: string, uuids: string) {
    const voyagesEndpointUrl = new URL(
      `/api/v1/voyages/bulk/assign?uuid=${uuids}&assignedTo=${assignedTo}`,
      this.siglarApiUrl
    );
    return this.http.patch<Voyage>(voyagesEndpointUrl.href, null);
  }

  resetVoyageReportRange(reportType: string, uuid: string) {
    const voyagesEndpointUrl = new URL(`/api/v1/voyages/${uuid}/reset?dateType=${reportType}`, this.siglarApiUrl);
    return this.http.get<Voyage>(voyagesEndpointUrl.href);
  }

  deleteVoyage(uuid: string) {
    const voyagesEndpointUrl = new URL(`/api/v1/voyages/${uuid}`, this.siglarApiUrl);
    return this.http.delete<{ data: any[] }>(voyagesEndpointUrl.href);
  }

  unverifyVoyage(uuid: string) {
    const voyagesEndpointUrl = new URL(`/api/v1/voyages/${uuid}/unverify`, this.siglarApiUrl);
    return this.http.post<any>(voyagesEndpointUrl.href, uuid);
  }

  addVoyageComment(comment: string, uuid: string) {
    const voyagesEndpointUrl = new URL(`/api/v1/voyages/${uuid}/comments`, this.siglarApiUrl);
    return this.http.post<{ body: { data: VoyageComment } }>(voyagesEndpointUrl.href, { comment });
  }

  updateVoyageComment(comment: FrontendVoyageComment, uuid: string) {
    const voyagesEndpointUrl = new URL(`/api/v1/voyages/${uuid}/comments/${comment.commentId}`, this.siglarApiUrl);
    return this.http.put<any>(voyagesEndpointUrl.href, comment);
  }

  deleteVoyageComment(commentId: number, uuid: string) {
    const voyagesEndpointUrl = new URL(`/api/v1/voyages/${uuid}/comments/${commentId}`, this.siglarApiUrl);
    return this.http.delete<any>(voyagesEndpointUrl.href);
  }

  patchPreferredVoyageDistance(uuid: string, preferredDistance: Record<string, PreferredDistance>) {
    const voyagesEndpointUrl = new URL(`/api/v1/voyages/${uuid}/distance`, this.siglarApiUrl);
    return this.http.patch<Voyage>(voyagesEndpointUrl.href, preferredDistance);
  }

  searchDuplicateCPID(term = '', companyUUID: string) {
    const reportsEndpointUrl = new URL(
      `/api/v1/autocomplete/voyage?query=${term}&companyUUID=${companyUUID}`,
      this.siglarApiUrl
    );
    return this.http.get<CompanyIdCharterer[]>(reportsEndpointUrl.href);
  }

  setStartReport(reportID: number, uuid: string) {
    const voyagesEndpointUrl = new URL(`/api/v1/voyages/${uuid}/set-start-report`, this.siglarApiUrl);
    return this.http.post<{ body: { data: VoyageUpdate } }>(voyagesEndpointUrl.href, { reportID });
  }

  setEndReport(reportID: number, uuid: string) {
    const voyagesEndpointUrl = new URL(`/api/v1/voyages/${uuid}/set-end-report`, this.siglarApiUrl);
    return this.http.post<{ body: { data: VoyageUpdate } }>(voyagesEndpointUrl.href, { reportID });
  }

  setVerified(data: { verified: boolean; remark: string }, uuid: string) {
    const voyagesEndpointUrl = new URL(`/api/v1/voyages/${uuid}/set-verified`, this.siglarApiUrl);
    return this.http.post<{ body: { data: VoyageUpdate } }>(voyagesEndpointUrl.href, data);
  }

  setVoyageTags(tags: VoyageTag[], uuid: string) {
    const voyagesEndpointUrl = new URL(`/api/v1/voyages/${uuid}/set-tags`, this.siglarApiUrl);
    return this.http.post<{ body: { data: VoyageUpdate } }>(voyagesEndpointUrl.href, { tags });
  }

  downloadVoyageStatement(url: string) {
    return this.http.get<Blob>(url, {
      observe: 'response',
      responseType: 'blob' as 'json',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/pdf',
      },
    });
  }
}
