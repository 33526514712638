import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BackendReponse, Ship, ShipSearchResultBackend, ShipUuid } from 'src/types';
@Injectable({ providedIn: 'root' })
export class ShipService {
  siglarApiUrl = environment.siglarApi.url;

  constructor(private http: HttpClient) {}

  loadShips() {
    const shipsEndpointUrl = new URL('/api/v1/ships', this.siglarApiUrl);
    return this.http.get<BackendReponse<Ship[]>>(shipsEndpointUrl.href);
  }

  getShip(imo: number) {
    const shipsEndpointUrl = new URL(`/api/v1/ships/${imo}`, this.siglarApiUrl);
    return this.http.get<BackendReponse<Ship[]>>(shipsEndpointUrl.href);
  }

  loadShipUuids() {
    const shipsUuidEndpointUrl = new URL(`/api/v1/ship_uuids`, this.siglarApiUrl);
    return this.http.get<BackendReponse<ShipUuid[]>>(shipsUuidEndpointUrl.href);
  }

  getShipUuid(uuid: string) {
    const shipsUuidEndpointUrl = new URL(`/api/v1/ship_uuids/${uuid}`, this.siglarApiUrl);
    return this.http.get<BackendReponse<ShipUuid[]>>(shipsUuidEndpointUrl.href);
  }

  getShipUuidWithImo(imo: number) {
    const shipsUuidEndpointUrl = new URL(`/api/v1/ship_uuids/${imo}`, this.siglarApiUrl);
    return this.http.get<BackendReponse<ShipUuid[]>>(shipsUuidEndpointUrl.href);
  }

  createLink(imo: number, chartererReference?: string) {
    const shipsUuidEndpointUrl = new URL(`/api/v1/ship_uuids/${imo}`, this.siglarApiUrl);
    return this.http.post<{ status: 'Created' | 'Error'; uuid: string }>(
      shipsUuidEndpointUrl.href,
      chartererReference ? { chartererReference } : null
    );
  }

  searchShips(term = '') {
    const shipsAutocompleteEndpoint = new URL(`/api/v1/autocomplete/ship?query=${term}`, this.siglarApiUrl);
    return this.http.get<ShipSearchResultBackend[]>(shipsAutocompleteEndpoint.href);
  }
}
