import { createSelector } from '@ngrx/store';
import { Port } from 'src/types';
import { getStructuredReport } from 'src/utils';
import { AppState } from '..';
import { selectPorts } from './port.selectors';

export const reportState = (state: AppState) => state.reports;

export const selectLoadingReports = createSelector(reportState, ({ loading }) => loading);

export const selectReportsMeta = createSelector(reportState, ({ excelImportStatus }) => excelImportStatus);

export const selectMultipleReportsMeta = createSelector(
  reportState,
  ({ multipleUpdateStatus }) => multipleUpdateStatus
);

export const selectReportErrors = createSelector(reportState, ({ errors = null }) => {
  const { errorFields, errorsList }: any = errors.hasOwnProperty('errorFields')
    ? errors
    : { errorFields: [], errorsList: [errors.error] };

  const reportErrors = {
    fields: errorFields ? Object.keys(errorFields) : [],
    errors: errorsList,
  };
  return reportErrors;
});

export const selectReports = createSelector(reportState, (reports) => getStructuredReport(reports?.entries));

export const selectReportPorts = createSelector(reportState, selectPorts, ({ entries }, ports) =>
  [...new Set(entries.flatMap((report) => [report.port, report.nextCargoPort, report.previousCargoPort]))].reduce(
    (portsMap, port) => (ports.size > 0 ? portsMap.set(port, ports.get(port)) : null),
    new Map<string, Port>()
  ));

export const selectVoyageReports = createSelector(reportState, ({ voyageReports = [] }) =>
  getStructuredReport(voyageReports)
);


