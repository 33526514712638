import { createSelector } from '@ngrx/store';
import { AppState } from '..';

export const selectCompanyState = (state: AppState) => state.companies;

export const selectCompanies = createSelector(selectCompanyState, ({ entries, searchFilter }) =>
  entries.filter((company) =>
    Object.values(company).some((entry) => String(entry).toLowerCase().includes(searchFilter.toLowerCase()))
  )
);

export const selectCompaniesLoading = createSelector(selectCompanyState, ({ loading }) => loading);
