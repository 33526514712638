<div class="sc-padding">
  <div class="flex between">
    <h3 class="dialog-title">{{ _data?.title }}</h3>
    <mat-icon class="cursor-pointer" (click)="dismiss()">close</mat-icon>
  </div>
  <div class="dialog-body">
    <div [innerHTML]="_data?.message"></div>
  </div>
  <div [ngClass]="_data?.btnOkText ? 'flex between' : 'flex justify-content-center'" class="sc-footer sc-padding-top">
    <button mat-stroked-button color="primary" (click)="dismiss()">Cancel</button>
    <button data-testid="confirm" *ngIf="_data?.btnOkText" mat-raised-button color="primary" (click)="confirm()">
      {{ _data?.btnOkText }}
    </button>
  </div>
</div>