import { VoyageFilters, DashboardFilters } from 'src/types';
import { typedKeys } from '../conversion.utils';

export const getUserDefinedFilters = (newFilters: Partial<VoyageFilters> = null) => {
  const definedFilters = JSON.parse(localStorage.getItem('filters'));
  const page = !newFilters ? definedFilters?.page || 1 : !!newFilters?.page ? newFilters?.page : 1;
  const nullifiedFilters = {
    sortBy: '',
    orderBy: '',
    assignedTo: '',
    companies: '',
    status: '',
    search: '',
    tags: '',
    registeredStartDate: '',
    registeredEndDate: '',
    cpStartDate: '',
    cpEndDate: '',
    type: '',
    verifiedStartDate: '',
    verifiedEndDate: '',
    page: 1,
    pageSize: 20,
  };
  const filters = {
    ...nullifiedFilters,
    ...definedFilters,
    ...newFilters,
    page,
  };

  return typedKeys(filters)
    .filter((key) => !!filters[key])
    .map((key) => ({ [key]: filters[key] }))
    .reduce((allFilters, filter) => ({ ...allFilters, ...filter }), {});
};

export function initializeDashboardFilters(): DashboardFilters {
  return {
    assigned_to: [''],
    voyage_status: [''],
    company: [''],
    anomaly_types: [''],
    include_verified_voyages: false,
    sort_order: '',
    search: '',
    startDate: '',
    endDate: '',
    sortBy: '',
    orderBy: '',
    startIndex: 0,
    endIndex: 20
  };
}

export function getAppliedFilters(filters: Partial<DashboardFilters>): string {
  return Object.entries(filters)
    .filter(([_key, values]) => !!values)
    .map(([_key, values]) => {
      if (Array.isArray(values)) {
        return `${_key}=${values.join(',')}`;
      } else {
        return `${_key}=${values}`;
      }
    })
    .join('&')
    .replace('orderBy', 'order_by')
    .replace('sortBy', 'sort_order')
    .replace('startDate', 'anomaly_date_from')
    .replace('endDate', 'anomaly_date_to');
}
