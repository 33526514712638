export const slugify = (word: string): string => {
  const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
  const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
  const p = new RegExp(a.split('').join('|'), 'g');

  return word
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
};

export const typedKeys = <T>(o: T): (keyof T)[] =>
  // type cast should be safe because that's what really Object.keys() does
  Object.keys(o) as (keyof T)[];

export const getTimezoneOffset = (offset: string) => {
  if (!offset) {
    return '+00:00';
  }
  const [negativeTimezone, includesPlusSign] = [offset.includes('-'), offset.includes('+')];
  const timezoneCharacters = negativeTimezone || includesPlusSign ? 6 : 5;
  const timezoneSlice = offset.slice(0, timezoneCharacters);
  return negativeTimezone || includesPlusSign ? timezoneSlice : '+' + timezoneSlice;
};
