import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BackendReponse, PortInterface, PortResponse } from 'src/types';

@Injectable({ providedIn: 'root' })
export class PortService {
  siglarApiUrl = environment.siglarApi.url;

  constructor(private http: HttpClient) { }

  searchPorts(term = '') {
    const portsEndpointUrl = new URL(`/api/v1/autocomplete/port?query=${term}`, this.siglarApiUrl);
    return this.http.get<PortResponse[]>(portsEndpointUrl.href);
  }

  getPorts() {
    const portsEndpointUrl = new URL('/api/v1/ports?refresh=true', this.siglarApiUrl);
    return this.http.get<BackendReponse<PortResponse[]>>(portsEndpointUrl.href);
  }

  createPort(report: Partial<PortInterface>) {
    const portsEndpointUrl = new URL(`/api/v1/ports`, this.siglarApiUrl);
    return this.http.post<BackendReponse<PortResponse>>(portsEndpointUrl.href, report);
  }

  updatePort(report: Partial<PortInterface>, portId: string) {
    const portsEndpointUrl = new URL(`/api/v1/ports/${portId}`, this.siglarApiUrl);
    return this.http.put<BackendReponse<PortResponse>>(portsEndpointUrl.href, report);
  }

  deletePort(portId: string) {
    const portsEndpointUrl = new URL(`/api/v1/ports/${portId}`, this.siglarApiUrl);
    return this.http.delete<{ data: any[] }>(portsEndpointUrl.href);
  }
}
