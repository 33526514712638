export const environment = {
  production: true,
  appUrl: 'https://staging.admin.siglarcarbon.com',
  auth0: {
    domain: 'auth.siglarcarbon.com',
    clientId: 'slM9HsYCOnxTMZzOz0C813JEplWL5XLl',
    redirect: 'auth/callback',
    scope: 'openid profile email',
    audience: 'https://api.siglarcarbon.com',
  },
  siglarApi: {
    url: 'https://staging.reporting.api.siglarcarbon.com',
  },
  shipReportApp: {
    url: 'https://staging-ciicf.reporting.siglarcarbon.com',
    demoUrl: 'https://staging-ciicf.reporting.siglarcarbon.com',
  },
  mapbox: {
    accessToken: 'pk.eyJ1IjoiZWt1bWFtYWl0IiwiYSI6ImNremNyYW9ndTAwMTQydm8wd3hzd2dzemUifQ.ql5lo-vpdC8ynx2DfZgNKA',
  },
};
