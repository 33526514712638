import { createAction, props } from '@ngrx/store';
import { Port, PortInterface, PortResponse } from 'src/types';

// eslint-disable-next-line no-shadow
export const enum PortActions {
  LoadPorts = '[PORT] Load Ports',
  LoadPortsSuccess = '[PORT] Load Ports Success',
  LoadPortsFailure = '[PORT] Load Ports Failure',
  SetFilter = '[PORT] Set Filter',
  ClearSearchFilter = '[PORT] Clear Search Filter',
  CreatePort = '[PORT] Create Port',
  CreatePortSuccess = '[PORT] Create Port Success',
  CreatePortFailure = '[PORT] Create Port Failure',
  UpdatePort = '[PORT] Update Port',
  UpdatePortSuccess = '[PORT] Update Port Success',
  UpdatePortFailure = '[PORT] Update Port Failure',
  DeletePort = '[PORT] Delete Port',
  DeletePortSuccess = '[PORT] Delete Port Success',
  DeletePortFailure = '[PORT] Delete Port Failure',
}

/** LOAD PORTS */
export const loadPorts = createAction(PortActions.LoadPorts, props<{ filters?: string }>());
export const loadPortsSuccess = createAction(PortActions.LoadPortsSuccess, props<{ ports: PortInterface[] }>());
export const loadPortsFailure = createAction(PortActions.LoadPortsFailure, props<{ error: any }>());

/** Search filter */
export const setFilter = createAction(
  PortActions.SetFilter,
  props<{
    searchValue?: string;
    searchSuggestion?: string;
    page?: number;
    pageSize?: number;
    sortBy?: keyof Port;
    orderBy?: string;
  }>()
);

/** Create Port */
export const createPort = createAction(PortActions.CreatePort, props<{ port: PortInterface }>());
export const createPortSuccess = createAction(PortActions.CreatePortSuccess, props<{ port: PortResponse }>());
export const createPortFailure = createAction(PortActions.CreatePortFailure, props<{ error: any }>());

/** Update Port */
export const updatePort = createAction(PortActions.UpdatePort, props<{ port: PortInterface; portId: string }>());
export const updatePortSuccess = createAction(PortActions.UpdatePortSuccess, props<{ port: PortResponse; portId: string }>());
export const updatePortFailure = createAction(PortActions.UpdatePortFailure, props<{ error: any }>());

/** Delete report */
export const deletePort = createAction(PortActions.DeletePort, props<{ portId: string }>());
export const deletePortSuccess = createAction(PortActions.DeletePortSuccess, props<{ portId: string }>());
export const deletePortFailure = createAction(PortActions.DeletePortFailure, props<{ error: any }>());
