import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { fromEvent } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { WorkBook, WorkSheet, read, utils } from 'xlsx';
import { ExcelReport, ShipLocation } from 'src/types';
import { convertToNumber } from './reports';
import {
  defaultTemplateColumns,
  defaultTemplateColumnsV3,
  defaultTemplateColumnsV4,
  defaultTemplateColumnsV5,
} from 'src/directories/voyage.directories';

export const DATE_PATTERN = '^\\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$';
export const TIME_PATTERN = '^([01][0-9]|2[0-3]):([0-5][0-9])$';
export const UTC_PATTERN = '^(?:([+-])(\\d{2}):(\\d{2}))$';

export function portValidator(control: AbstractControl) {
  if (!control.parent) {
    return null;
  }

  const { value: type } = control.parent.get('reportType');

  if (type === 'cosp' || type === 'eosp') {
    return Validators.required(control);
  }

  return null;
}

export function nextPortValidator(control: AbstractControl) {
  if (!control.parent) {
    return null;
  }

  const { value: type } = control.parent.get('reportType');

  if (type === 'cast_off') {
    return Validators.required(control);
  }

  return null;
}

export const setPortAndLocationValues = (portControl: AbstractControl, locationControl: AbstractControl) => {
  const portValue = portControl.value;
  if (portValue === '') {
    locationControl.setValue('at_sea');
  } else if (portValue?.toUpperCase() === 'AT SEA') {
    portControl.setValue('');
    locationControl.setValue('at_sea');
  }
};

export const parseExcelReport = (evt: File, version: number) => {
  const reader: FileReader = new FileReader();
  reader.readAsDataURL(evt); //TODO test this implementation

  return fromEvent(reader, 'loadend').pipe(
    map((e) => {
      const { result } = e.target as FileReader;
      const wb: WorkBook = read(result, {
        type: 'binary',
        sheets: 0,
        cellDates: true,
      });

      const wsname: string = wb.SheetNames[0];
      const ws: WorkSheet = wb.Sheets[wsname];

      const data = utils.sheet_to_json(ws, { blankrows: false, defval: '' });
      const jsonData = data.slice(12).map(Object.values);

      return jsonData;
    }),
    map((data) => parseExcelContentToForm(data, version, 'dropped')),
    catchError((error) => {
      throw error;
    })
  );
};

export const parseExcelContentToForm = (
  content: string | string[][],
  version: number,
  source = 'clipboard'
): UntypedFormGroup[] => {
  const excelArray = Array.isArray(content) ? content : convertExcelToArray(content, '\t');

  let columns = defaultTemplateColumns;
  switch (version) {
    case 5:
      columns = defaultTemplateColumnsV5;
      break;

    case 4:
      columns = defaultTemplateColumnsV4;
      break;

    case 3:
      columns = defaultTemplateColumnsV3;
      break;

    default:
      columns = defaultTemplateColumns;
      break;
  }

  return excelArray.map((array: string[]) => {
    const values = array
      .map((value, idx) => {
        const columnName =
          columns.length >= idx + 1 ? (columns[idx] as keyof ExcelReport) : (`column-${idx + 1}` as keyof any);

        if (columnName === 'reportType') {
          return { [columnName as keyof ExcelReport]: String(value).toLocaleLowerCase().replace(' ', '_') };
        }

        if (columnName === 'location') {
          return { [columnName as keyof ExcelReport]: String(value).toLocaleLowerCase().replace(/\s/g, '_') };
        }

        return { [columnName]: value };
      })
      .reduce<ExcelReport>((allValues, value) => ({ ...allValues, ...value }), {} as ExcelReport);

    const {
      reportDate,
      localTime,
      utcOffset,
      reportType,
      port,
      location: stsLocation,
      previousCargoPort,
      nextCargoPort,
      sailedDistanceNm = 0,
      cargoQuantityMT = 0,
      speedOverGroundKT = 0,
      longitude = 0,
      latitude = 0,
      primaryRob,
      primaryCons,
      primaryRcv = 0,
      secondaryRob,
      secondaryCons,
      secondaryRcv = 0,
      tertiaryRob,
      tertiaryCons,
      tertiaryRcv,
      remark,
      speedThroughWaterKT,
      cargoQuantityM3,
      timeSinceLastReport,
      timeAtAnchor,
      timeDrifting,
      primaryHeatingBoilerMT,
      secondaryHeatingBoilerMT,
      tertiaryHeatingBoilerMT,
      primaryDischargeBoilerMT,
      secondaryDischargeBoilerMT,
      tertiaryDischargeBoilerMT,
      primaryDischargeStandaloneMT,
      secondaryDischargeStandaloneMT,
      tertiaryDischargeStandaloneMT,
      dischargeKWH,
      dischargeSFOCGKWH,
      coolingKWH,
      coolingSFOCGKWH,
    } = values;

    const time = typeof localTime === 'string' ? localTime : moment(localTime).format('HH:mm');
    const location: ShipLocation = !!stsLocation ? stsLocation : reportType === 'noon' && !port ? 'at_sea' : 'in_port';
    const date = source === 'clipboard' ? reportDate : new Date(reportDate).toISOString().slice(0, 10);

    const formGroup = new UntypedFormGroup({
      reportDate: new UntypedFormControl(date, Validators.compose([Validators.required, Validators.pattern(DATE_PATTERN)])),
      localTime: new UntypedFormControl(time, Validators.compose([Validators.required, Validators.pattern(TIME_PATTERN)])),
      utcOffset: new UntypedFormControl(utcOffset, Validators.compose([Validators.required, Validators.pattern(UTC_PATTERN)])),
      reportType: new UntypedFormControl(reportType, Validators.required),
      port: new UntypedFormControl(port, portValidator),
      location: new UntypedFormControl(location),
      previousCargoPort: new UntypedFormControl(previousCargoPort),
      nextCargoPort: new UntypedFormControl(nextCargoPort),
      sailedDistanceNm: new UntypedFormControl(
        convertToNumber(sailedDistanceNm, true),
        Validators.compose([Validators.required, Validators.min(0), Validators.max(2000)])
      ),
      cargoQuantityMT: new UntypedFormControl(convertToNumber(cargoQuantityMT, true), Validators.required),
      speedOverGroundKT: new UntypedFormControl(
        convertToNumber(speedOverGroundKT),
        Validators.compose([Validators.min(0), Validators.max(40)])
      ),
      longitude: new UntypedFormControl(
        convertToNumber(longitude),
        Validators.compose([Validators.min(-360), Validators.max(360)])
      ),
      latitude: new UntypedFormControl(
        convertToNumber(latitude),
        Validators.compose([Validators.min(-180), Validators.max(180)])
      ),
      primaryRob: new UntypedFormControl(convertToNumber(primaryRob), Validators.required),
      primaryCons: new UntypedFormControl(
        convertToNumber(primaryCons),
        Validators.compose([Validators.required, Validators.min(0), Validators.max(1000)])
      ),
      primaryRcv: new UntypedFormControl(convertToNumber(primaryRcv, true), Validators.required),
      secondaryRob: new UntypedFormControl(convertToNumber(secondaryRob)),
      secondaryCons: new UntypedFormControl(convertToNumber(secondaryCons)),
      secondaryRcv: new UntypedFormControl(convertToNumber(secondaryRcv)),
      tertiaryRob: new UntypedFormControl(convertToNumber(tertiaryRob)),
      tertiaryCons: new UntypedFormControl(convertToNumber(tertiaryCons)),
      tertiaryRcv: new UntypedFormControl(convertToNumber(tertiaryRcv)),
      remark: new UntypedFormControl(remark),
      speedThroughWaterKT: new UntypedFormControl(convertToNumber(speedThroughWaterKT)),
      cargoQuantityM3: new UntypedFormControl(convertToNumber(cargoQuantityM3)),
      timeSinceLastReport: new UntypedFormControl(convertToNumber(timeSinceLastReport)),
      timeAtAnchor: new UntypedFormControl(convertToNumber(timeAtAnchor)),
      timeDrifting: new UntypedFormControl(convertToNumber(timeDrifting)),
      primaryHeatingBoilerMT: new UntypedFormControl(convertToNumber(primaryHeatingBoilerMT)),
      secondaryHeatingBoilerMT: new UntypedFormControl(convertToNumber(secondaryHeatingBoilerMT)),
      tertiaryHeatingBoilerMT: new UntypedFormControl(convertToNumber(tertiaryHeatingBoilerMT)),
      primaryDischargeBoilerMT: new UntypedFormControl(convertToNumber(primaryDischargeBoilerMT)),
      secondaryDischargeBoilerMT: new UntypedFormControl(convertToNumber(secondaryDischargeBoilerMT)),
      tertiaryDischargeBoilerMT: new UntypedFormControl(convertToNumber(tertiaryDischargeBoilerMT)),
      primaryDischargeStandaloneMT: new UntypedFormControl(convertToNumber(primaryDischargeStandaloneMT)),
      secondaryDischargeStandaloneMT: new UntypedFormControl(convertToNumber(secondaryDischargeStandaloneMT)),
      tertiaryDischargeStandaloneMT: new UntypedFormControl(convertToNumber(tertiaryDischargeStandaloneMT)),
      dischargeKWH: new UntypedFormControl(convertToNumber(dischargeKWH)),
      dischargeSFOCGKWH: new UntypedFormControl(convertToNumber(dischargeSFOCGKWH)),
      coolingKWH: new UntypedFormControl(convertToNumber(coolingKWH)),
      coolingSFOCGKWH: new UntypedFormControl(convertToNumber(coolingSFOCGKWH)),
    });
    setPortAndLocationValues(formGroup.get('port'), formGroup.get('location'));
    return formGroup;
  });
};

export const convertExcelToArray = (content: string, separator: string) =>
  content
    .trim()
    .split('\n')
    .map((line: string) => line.split(separator).map((field) => field.trim()));
