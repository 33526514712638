import { createSelector } from '@ngrx/store';
import { Ship } from 'src/types';
import { AppState } from '..';

export const selectShipState = (state: AppState) => state.ships;

export const getShips = createSelector(selectShipState, ({ entries }) => entries);

export const selectShipsSearchResults = createSelector(selectShipState, ({ searchResults }) => searchResults);

export const getSelectedShip = createSelector(selectShipState, ({ entries = [], selectedShipImo }) => {
  const selectedShip = entries && entries.find(({ imo }) => imo === Number(selectedShipImo));
  return selectedShip ? selectedShip : ({} as Ship);
});

export const selectShipNameByImo = (shipImo: number) =>
  createSelector(selectShipState, ({ entries }) => {
    if (entries?.length) {
      const ship = entries.find(({ imo }) => imo === shipImo);
      return ship ? ship.name : '';
    }
    return '';
  });

export const selectShipUuids = createSelector(selectShipState, ({ shipUuids = [] }) => shipUuids);
export const selectShipLoading = createSelector(selectShipState, ({ loading }) => loading);
