import {
  BackendReport,
  Bunker,
  CIICorrectionFactors,
  Port,
  Report,
  ReportBunkers,
  ReportFormInterface,
} from 'src/types';
import { getTimezoneOffset, typedKeys } from '../conversion.utils';
import { bunkerTypes } from 'src/directories';
import { compareDate } from '../sort.utils';
import moment from 'moment';
import packageJson from '../../../package.json';

export const getPort = (ports: Map<string, Port> = new Map(), portCode: string) => {
  // TODO: This is only to fix the ports problem. Change to ports.get(port) once ports are standardized in backend
  if (!portCode || !ports.size) {
    return null;
  }

  const portCodeSubstring = portCode.substring(0, 5);
  return ports?.get(portCodeSubstring);
};

export const getPortName = (ports: Map<string, Port>, portCode: string) => {
  const port = ports && getPort(ports, portCode);
  return port ? port.name : portCode || '';
};

export const convertToReportForm = (report: Report, ports: Map<string, Port>) => {
  if (!report) {
    return null;
  }

  const {
    reportedTimeLocal,
    utcOffset,
    port: portValue,
    bunkers: reportBunkers,
    speedOverGroundKT: sailingSpeed,
    nextCargoPort: nextPort,
    previousCargoPort: previousPort,
    latitude: lat,
    longitude: long,
  } = report;

  const reportDate = reportedTimeLocal.toString().slice(0, 10);
  const port = getPort(ports, portValue);
  const nextCargoPort = getPort(ports, nextPort);
  const previousCargoPort = getPort(ports, previousPort);
  const timeStartIndex = reportedTimeLocal.toString().indexOf('T') + 1;
  const timeEndIndex = timeStartIndex + 5;
  const localTime = reportedTimeLocal.toString().slice(timeStartIndex, timeEndIndex);
  const speedOverGroundKT = sailingSpeed === -1 ? null : sailingSpeed;
  const latitude = !!lat || lat === 0 ? Number(lat.toFixed(5)) : null;
  const longitude = !!long || long === 0 ? Number(long.toFixed(5)) : null;
  const timezone = getTimezoneOffset(utcOffset);

  const bunkers: Bunker[] = [];

  Object.keys(reportBunkers).forEach((grade) => {
    const {
      consumptionMT,
      remainingOnBoardMT,
      receivedMT,
      calculatedConsumptionMT,
      previousROB,
      previousCons,
      ...rest
    } = reportBunkers[grade];

    bunkers.push({
      ...rest,
      grade,
      consumptionMT,
      remainingOnBoardMT,
      receivedMT,
      calculatedConsumptionMT,
      previousROB,
      previousCons,
    });
  });

  return {
    ...report,
    reportDate,
    timezone,
    localTime,
    port,
    bunkers,
    speedOverGroundKT,
    latitude,
    longitude,
    nextCargoPort,
    previousCargoPort,
  } as ReportFormInterface;
};

export const convertToBackendReport = (
  report: ReportFormInterface,
  source: 'siglar-reports-admin' | 'csv-import' = 'siglar-reports-admin'
): Partial<BackendReport> => {
  const {
    id,
    bunkers: reportBunkers = [],
    reportDate,
    localTime,
    port,
    timezone,
    nextCargoPort,
    previousCargoPort,
    utcOffset,
    ciiCorrectionFactors: ciiFactors,
  } = report;

  const reportedTime =
    typeof reportDate === 'string' && reportDate.length > 10 ? reportDate : `${reportDate}T${localTime}:00${timezone}`;

  const filteredReport = typedKeys(report)
    .filter((key) => report[key] === 0 || key === 'remark' || !!report[key])
    .filter((key) => key !== 'reportDate')
    .reduce((accum, key) => ({ ...accum, [key]: report[key] }), {});

  const ciiCorrectionFactors: CIICorrectionFactors[] = [];
  const bunkers = reportBunkers.map((bunker) => {
    const {
      grade,
      coolingKWH,
      coolingSFOCGKWH,
      dischargeBoilerMT,
      dischargeKWH,
      dischargeSFOCGKWH,
      dischargeStandaloneMT,
      heatingBoilerMT,
    } = bunker;

    if (
      coolingKWH >= 0 ||
      coolingSFOCGKWH >= 0 ||
      dischargeBoilerMT >= 0 ||
      dischargeKWH >= 0 ||
      dischargeSFOCGKWH >= 0 ||
      dischargeStandaloneMT >= 0 ||
      heatingBoilerMT >= 0
    ) {
      const cii = ciiFactors.find(({ grade: grd }) => grade === grd);
      ciiCorrectionFactors.push({
        id: cii?.id,
        grade,
        coolingKWH,
        coolingSFOCGKWH,
        dischargeBoilerMT,
        dischargeKWH,
        dischargeSFOCGKWH,
        dischargeStandaloneMT,
        heatingBoilerMT,
        reportId: Number(id),
      });
    }
    return { ...bunker, reportId: Number(id) };
  });

  const newReport: Partial<BackendReport> = {
    ...filteredReport,
    id: Number(id),
    bunkers,
    ciiCorrectionFactors,
    reportedTime,
    utcOffset: report?.utcOffset ? utcOffset : timezone,
    source: report?.source || source,
  };

  newReport.port = port ? port.unlocode : undefined;
  newReport.nextCargoPort = nextCargoPort ? nextCargoPort.unlocode : undefined;
  newReport.previousCargoPort = previousCargoPort ? previousCargoPort.unlocode : undefined;
  newReport.sourceVersion = packageJson.version;
  return newReport;
};

export const convertToBulkBackendReport = (
  report: Partial<ReportFormInterface>,
  source: 'siglar-reports-admin' | 'csv-import' = 'csv-import'
): Partial<BackendReport> => {
  const {
    bunkers = [],
    reportType,
    reportDate,
    localTime,
    utcOffset,
    cargoQuantityMT,
    port,
    nextCargoPort,
    previousCargoPort,
    timezone,
  } = report;
  const reportedTime =
    typeof reportDate === 'string' && reportDate.length > 10 ? reportDate : `${reportDate}T${localTime}:00${utcOffset}`;

  const altReportedTime =
    typeof reportDate === 'string' && reportDate.length > 10 ? reportDate : `${reportDate}T${localTime}:00${timezone}`;

  const filteredReport = typedKeys(report)
    .filter((key) => report[key] === 0 || key === 'remark' || !!report[key])
    .filter((key) => key !== 'reportDate')
    .filter((key) => key !== 'id')
    .reduce((accum, key) => ({ ...accum, [key]: report[key] }), {});

  const newReport: Partial<BackendReport> = {
    ...filteredReport,
    bunkers,
    condition: reportType === 'cast_off' || reportType === 'cosp' ? (cargoQuantityMT > 0 ? 'laden' : 'ballast') : null,
    reportedTime: report?.utcOffset ? reportedTime : altReportedTime,
    utcOffset: report?.utcOffset ? utcOffset : timezone,
    source,
  };
  newReport.port = port ? port.unlocode : undefined;
  newReport.nextCargoPort = nextCargoPort ? nextCargoPort.unlocode : undefined;
  newReport.previousCargoPort = previousCargoPort ? previousCargoPort.unlocode : undefined;
  newReport.sourceVersion = packageJson.version;
  return newReport;
};

export const convertToBulkEditBackendReport = (report: Partial<ReportFormInterface>): Partial<BackendReport> => {
  const {
    id,
    bunkers: reportBunkers = [],
    reportDate,
    localTime,
    utcOffset,
    port,
    nextCargoPort,
    previousCargoPort,
    timezone,
    source,
  } = report;
  const reportedTime =
    typeof reportDate === 'string' && reportDate.length > 10 ? reportDate : `${reportDate}T${localTime}:00${utcOffset}`;

  const altReportedTime =
    typeof reportDate === 'string' && reportDate.length > 10 ? reportDate : `${reportDate}T${localTime}:00${timezone}`;

  const filteredReport = typedKeys(report)
    .filter((key) => report[key] === 0 || key === 'remark' || !!report[key])
    .filter((key) => key !== 'reportDate')
    .filter((key) => key !== 'id')
    .reduce((accum, key) => ({ ...accum, [key]: report[key] }), {});

  const ciiCorrectionFactors: CIICorrectionFactors[] = [];
  const bunkers = reportBunkers.map((bunker) => {
    const {
      grade,
      coolingKWH,
      coolingSFOCGKWH,
      dischargeBoilerMT,
      dischargeKWH,
      dischargeSFOCGKWH,
      dischargeStandaloneMT,
      heatingBoilerMT,
    } = bunker;

    if (
      coolingKWH ||
      coolingSFOCGKWH ||
      dischargeBoilerMT ||
      dischargeKWH ||
      dischargeSFOCGKWH ||
      dischargeStandaloneMT ||
      heatingBoilerMT
    ) {
      ciiCorrectionFactors.push({
        grade,
        coolingKWH,
        coolingSFOCGKWH,
        dischargeBoilerMT,
        dischargeKWH,
        dischargeSFOCGKWH,
        dischargeStandaloneMT,
        heatingBoilerMT,
        reportId: Number(id),
      });
    }

    return { ...bunker, reportId: Number(id) };
  });

  const newReport: Partial<BackendReport> = {
    ...filteredReport,
    bunkers,
    ciiCorrectionFactors,
    reportedTime: report?.utcOffset ? reportedTime : altReportedTime,
    utcOffset: report?.utcOffset ? utcOffset : timezone,
    source,
  };
  newReport.port = port ? port.unlocode : undefined;
  newReport.nextCargoPort = nextCargoPort ? nextCargoPort.unlocode : undefined;
  newReport.previousCargoPort = previousCargoPort ? previousCargoPort.unlocode : undefined;
  newReport.sourceVersion = packageJson.version;
  return newReport;
};

export const getCurrentBunkerTypes = (reports: Partial<Report>[]) =>
  reports
    .reduce((allBunkers, { bunkers }) => [...allBunkers, ...typedKeys(bunkers)], [])
    .reduce((uniqueKeys: any, key: string) => (uniqueKeys.includes(key) ? uniqueKeys : [...uniqueKeys, key]), [])
    .map((bunker: string) => (!!bunker ? bunker : 'INVALID'))
    .sort((a: any, b: any) => bunkerTypes.indexOf(a) - bunkerTypes.indexOf(b));

export const convertToNumber = (value: number | string, required = false) => {
  if (!value && value !== 0) {
    return required ? 0 : null;
  }

  if (value === '0,00' || value === '0') {
    return 0;
  }

  if (typeof value === 'number') {
    return value;
  }

  const formattedValue = value.replace(',', '.');
  return Number(formattedValue) || null;
};

export const getStructuredReport = (entries: BackendReport[] = []) => {
  const reports = [];
  let lastNoonReportTime = null;
  const clonedEntries = [...entries].sort((a, b) =>
    compareDate(new Date(a.reportedTime), new Date(b.reportedTime), true)
  );
  for (let idx = 0; idx < clonedEntries.length; idx++) {
    const report = clonedEntries[idx];
    const first = idx === 0;
    const bunkers: ReportBunkers = {};
    const { bunkers: previousBunkers } = first ? { bunkers: undefined } : clonedEntries[idx - 1];
    const previousReport = first ? { reportedTimeLocal: '', bunkers, reportType: '' } : clonedEntries[idx - 1];
    report.bunkers?.forEach(({ remainingOnBoardMT = 0, consumptionMT = 0, receivedMT = 0, grade, ...rest }) => {
      const previousBunkerValue =
        previousBunkers && previousBunkers.find(({ grade: previousGrade }: Bunker) => previousGrade === grade);

      let calculatedConsumptionMT = 0;
      if (!first && previousBunkerValue && typeof previousBunkerValue.remainingOnBoardMT === 'number') {
        calculatedConsumptionMT = Number(
          (previousBunkerValue.remainingOnBoardMT + receivedMT - remainingOnBoardMT)?.toFixed(3)
        );
      }

      const cii = report?.ciiCorrectionFactors.find(({ grade: ciiGrade }) => grade === ciiGrade);

      bunkers[grade] = {
        ...rest,
        grade,
        coolingKWH: cii?.coolingKWH,
        coolingSFOCGKWH: cii?.coolingSFOCGKWH,
        dischargeBoilerMT: cii?.dischargeBoilerMT,
        dischargeKWH: cii?.dischargeKWH,
        dischargeSFOCGKWH: cii?.dischargeSFOCGKWH,
        dischargeStandaloneMT: cii?.dischargeStandaloneMT,
        heatingBoilerMT: cii?.heatingBoilerMT,
        remainingOnBoardMT: Number(remainingOnBoardMT?.toFixed(3)),
        consumptionMT: Number(consumptionMT?.toFixed(3)),
        calculatedConsumptionMT: calculatedConsumptionMT > 0 ? calculatedConsumptionMT : 0,
        receivedMT: receivedMT || 0,
        previousROB: Number(previousBunkerValue?.remainingOnBoardMT?.toFixed(3)) || 0,
        previousCons: Number(previousBunkerValue?.consumptionMT?.toFixed(3)) || 0,
        previousRcv: Number(previousBunkerValue?.receivedMT?.toFixed(3)) || 0,
      };
    });

    const cargoQuantityMT =
      typeof report.cargoQuantityMT === 'number' ? Number(report.cargoQuantityMT.toFixed(3)) : report.cargoQuantityMT;

    const sailedDistanceNm =
      typeof report.sailedDistanceNm === 'number'
        ? Number(report.sailedDistanceNm.toFixed(3))
        : report.sailedDistanceNm;

    let hasMissingReport = false;
    let reportedTimeLocal;
    if (!first) {
      const isConsecutiveNoon = report.reportType === 'noon' && previousReport.reportType === 'noon';
      const lastNoonReportDate = moment(lastNoonReportTime);
      const previousDate = moment(previousReport.reportedTimeLocal);
      const currentDate = moment(report.reportedTimeLocal);
      hasMissingReport =
        currentDate.diff(previousDate, 'days') > 1 ||
        (currentDate.diff(lastNoonReportDate, 'hours') > 24 &&
          currentDate.diff(previousDate, 'days', true) > 1 &&
          !isConsecutiveNoon);
    }

    if (report.reportType === 'noon' || String(report.reportedTimeLocal).slice(11, 16) === '12:00') {
      lastNoonReportTime = report.reportedTimeLocal;
    }

    if (hasMissingReport) {
      const missingReport = {
        id: report.id * 1000,
        reportedTimeLocal,
        bunkers: [],
        qa: {
          warnings: { hasMissingReport },
          errors: {},
        },
        hasMissingReport,
      } as unknown as Report;
      reports.push(missingReport);
    }
    const hasKnutsenWarning = report?.externalAttributes?.knutsenLoadingDischargingWarning;
    const qa = hasKnutsenWarning ? { ...report.qa, warnings: { ...report.qa.warnings, hasKnutsenWarning } } : report.qa;

    reports.push({ ...report, bunkers, cargoQuantityMT, sailedDistanceNm, qa });
  }
  return reports;
};
