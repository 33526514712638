import { environment } from 'src/environments/environment';
import {
  Company,
  ShipUuid,
  Voyage,
  VoyageAnalysisCompact,
  VoyageForm,
  VoyageItem,
  VoyageListItem,
  VoyageAnomalies,
  VoyageStatus,
} from 'src/types';
import { slugify } from '../conversion.utils';

/**
 *
 * @param voyage Can be VoyageForm, Voyage or a partial Voyage
 * @returns A backend compatible version of the voyage
 */
export const backendVoyage = (voyage: VoyageForm | Voyage | Partial<Voyage>): Voyage => {
  const { charterpartyDate: initialCpDate } = voyage;

  if (initialCpDate) {
    const charterpartyDate =
      typeof initialCpDate === 'string' && initialCpDate.length > 10 ? initialCpDate : initialCpDate + 'T12:00:00Z';
    return { ...voyage, charterpartyDate } as Voyage;
  }

  return voyage as Voyage;
};

/**
 *
 * @param voyageAnalyis Compact view of a voyage analysis
 * @param companies Array of companies
 * @param ships Array of ships
 * @param shipUuids Array of ship uuids
 * @returns Voyage item
 */
export const voyageItem = (
  voyageAnalyis: VoyageAnalysisCompact,
  companies: Company[],
  shipUuids: ShipUuid[]
): VoyageItem => {
  const {
    voyage: {
      uuid,
      imo,
      charterpartyDate,
      updated,
      created,
      remark,
      idCharterer,
      tags: initialTags,
      startReportId,
      endReportId,
      companyUuid,
      type,
      verified,
      reportingMethod,
      assignedTo,
      verifiedBy,
    },
    comments,
    voyageLegs,
    qa: { errors, warnings },
    report: { ship },
  } = voyageAnalyis;
  const errorCount = Object.values(errors).filter((value) => value).length;
  const warningCount = Object.values(warnings).filter((value) => value).length;

  const tags = initialTags?.length ? initialTags : [];
  const [ownersOption, linkSent] = [tags.includes('oo'), tags.includes('link sent')];

  const hasEndReport = !!endReportId;
  const hasStartReport = !!startReportId;
  const isAssumedEnded = !!startReportId && warnings?.secondBallastLegNoEndReport && !endReportId;

  const status = getVoyageStatus({ tags, hasStartReport, hasEndReport, isAssumedEnded } as VoyageItem, !!verified);
  const { name: chartererName } = companies.find(({ uuid: companyId }) => companyId === companyUuid) || {};

  const isMissingCargo = voyageLegs && voyageLegs.some(({ qa }) => qa.errors.ladenLegZeroCargoQty);

  const lastVoyageLeg = voyageLegs && voyageLegs[voyageLegs.length - 1];
  const lastReport = lastVoyageLeg && lastVoyageLeg.reports && lastVoyageLeg.reports[lastVoyageLeg.reports.length - 1];

  const lastReportReceived = lastReport ? lastReport.updated : '';

  let chartererEmail = '';
  tags.map((tag) => {
    if (tag.includes('chartererEmail:')) {
      chartererEmail = tag.split(':')[1];
    }
  });

  if (!ship) {
    return {
      charterpartyDate,
      updated,
      created,
      remark,
      idCharterer,
      tags,
      ownersOption,
      linkSent,
      status,
      hasEndReport: !!endReportId,
      hasStartReport: !!startReportId,
      hasNoReports: errors?.noReports,
      isAssumedEnded,
      isMissingCargo,
      chartererName,
      type,
      assignedTo,
      verified,
      voyageLegs,
      lastReportReceived,
      comments,
      chartererEmail,
      verifiedBy,
    } as VoyageItem;
  }
  const { uuid: shipUuid } =
    (shipUuids && shipUuids.find(({ imo: entryImo }) => imo === entryImo)) || ({} as ShipUuid) || ({} as ShipUuid);

  const { cubicMeters, deadWeightTon, name: shipName = '(name missing)' } = ship;

  const reportLink = shipUuid
    ? `${environment.shipReportApp.url}/${shipUuid}?n=${slugify(shipName)}${idCharterer ? '&cpid=' + idCharterer : ''}`
    : '';

  return {
    uuid,
    shipName,
    remark,
    imo,
    charterpartyDate,
    idCharterer,
    updated,
    created,
    reportLink,
    tags,
    ownersOption,
    linkSent,
    status,
    hasEndReport: !!endReportId,
    hasStartReport: !!startReportId,
    hasNoReports: errors?.noReports,
    isAssumedEnded,
    isMissingCargo,
    chartererName,
    companyUuid,
    type,
    cubicMeters,
    deadWeightTon,
    assignedTo,
    reportingMethod,
    verified,
    voyageLegs,
    lastReportReceived,
    errorCount,
    warningCount,
    comments,
    chartererEmail,
    verifiedBy,
  } as VoyageItem;
};

/**
 *
 * @param voyage Voyage object
 * @param isVerified True if the voyage is verified
 * @returns The status of the voyage
 */
export const getVoyageStatus = (
  { hasStartReport, hasEndReport, isAssumedEnded }: VoyageItem,
  isVerified: boolean
): VoyageStatus => {
  if (!hasStartReport) {
    return 'not started';
  }
  if (isAssumedEnded) {
    return 'assumed ended';
  }
  if (!hasEndReport) {
    return 'underway';
  }
  if (isVerified) {
    return 'verified';
  }
  return 'completed';
};

export const voyageList = (
  voyage: VoyageListItem,
  shipUuids: ShipUuid[],
  anomalies: VoyageAnomalies[]
): VoyageListItem => {
  const { idCharterer, shipName, imo, uuid, tags = [] } = voyage;
  const { uuid: shipUuid } = (shipUuids && shipUuids.find(({ imo: entryImo }) => entryImo === imo)) || ({} as ShipUuid);
  const reportLink = shipUuid
    ? `${environment.shipReportApp.url}/${shipUuid}?n=${slugify(shipName)}${idCharterer ? '&cpid=' + idCharterer : ''}`
    : '';

  const anomaly = anomalies?.find(({ uuid: anomalyId }) => anomalyId === uuid);
  const chartererEmail = tags?.find((tag) => tag.includes('chartererEmail'))?.split(':')[1];
  const brokerName = tags?.find((tag) => tag.includes('brokerName'))?.split(':')[1];

  return {
    ...voyage,
    reportLink,
    anomalyCount: anomaly?.anomalies,
    chartererEmail,
    brokerName,
  };
};
