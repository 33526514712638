import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import moment from 'moment';
import { bunkerTypes } from 'src/directories';
import { ReportFormInterface } from 'src/types';

export const createReportForm = ({
  id = 'new',
  reportDate = moment().format('yyyy-MM-DD'),
  localTime = '12:00',
  timezone = '',
  reportType = null,
  condition = null,
  sailedDistanceNm = null,
  speedThroughWaterKT = null,
  timeAtAnchor = null,
  timeDrifting = null,
  speedOverGroundKT = null,
  cargoQuantityMT = null,
  cargoQuantityCars = null,
  cargoQuantityM3 = null,
  cargoQuantityParcelMT = null,
  cargoQuantityTEU = null,
  ciiCorrectionFactors = [],
  location = 'at_berth',
  latitude = null,
  longitude = null,
  port = null,
  portActivity = null,
  nextCargoPort = null,
  previousCargoPort = null,
  modeOfOperation = null,
  timeSinceLastReport = null,
  remark = '',
  internalRemark = '',
  bunkers = [],
  anomalies = [],
  externalAttributes = null,
  ciiPumpTypeAsReported = '',
  ciiShipTypeAsReported = ''
}: ReportFormInterface) =>
  new UntypedFormGroup({
    id: new UntypedFormControl(id),
    reportDate: new UntypedFormControl(reportDate, Validators.compose([Validators.required, validateReportDate()])),
    localTime: new UntypedFormControl(localTime, Validators.required),
    timezone: new UntypedFormControl(timezone, Validators.required),
    reportType: new UntypedFormControl(reportType, Validators.required),
    condition: new UntypedFormControl(condition !== 'unknown' ? condition : null, Validators.required),
    sailedDistanceNm: new UntypedFormControl(sailedDistanceNm, Validators.required),
    speedThroughWaterKT: new UntypedFormControl(speedThroughWaterKT),
    timeAtAnchor: new UntypedFormControl(timeAtAnchor),
    timeDrifting: new UntypedFormControl(timeDrifting),
    speedOverGroundKT: new UntypedFormControl(speedOverGroundKT?.toFixed(1), Validators.required),
    cargoQuantityMT: new UntypedFormControl(cargoQuantityMT, Validators.required),
    cargoQuantityCars: new UntypedFormControl(cargoQuantityCars, Validators.required),
    cargoQuantityM3: new UntypedFormControl(cargoQuantityM3, Validators.required),
    cargoQuantityParcelMT: new UntypedFormControl(cargoQuantityParcelMT, Validators.required),
    cargoQuantityTEU: new UntypedFormControl(cargoQuantityTEU, Validators.required),
    ciiCorrectionFactors: new UntypedFormControl(ciiCorrectionFactors),
    location: new UntypedFormControl(location, Validators.required),
    latitude: new UntypedFormControl(latitude, Validators.compose([Validators.min(-90), Validators.max(90)])),
    longitude: new UntypedFormControl(longitude, Validators.compose([Validators.min(-180), Validators.max(180)])),
    port: new UntypedFormControl(port, Validators.required),
    portActivity: new UntypedFormControl(portActivity !== 'unknown' ? portActivity : null, Validators.required),
    nextCargoPort: new UntypedFormControl(nextCargoPort, Validators.required),
    previousCargoPort: new UntypedFormControl(previousCargoPort, Validators.required),
    modeOfOperation: new UntypedFormControl(modeOfOperation),
    timeSinceLastReport: new UntypedFormControl(timeSinceLastReport),
    ciiPumpTypeAsReported: new UntypedFormControl(ciiPumpTypeAsReported),
    ciiShipTypeAsReported: new UntypedFormControl(ciiShipTypeAsReported),
    externalAttributes: new UntypedFormControl(externalAttributes),
    remark: new UntypedFormControl(remark),
    internalRemark: new UntypedFormControl(internalRemark),
    bunkers: new UntypedFormArray(
      bunkers
        .sort((a: any, b: any) => bunkerTypes.indexOf(a.grade) - bunkerTypes.indexOf(b.grade))
        .map(
          ({
            id: bunkerId,
            grade,
            receivedMT = 0,
            previousROB = 0,
            previousCons = 0,
            consumptionMT = 0,
            remainingOnBoardMT = 0,
            auxConsumptionMT = null,
            boilerConsumptionMT = null,
            calculatedConsumptionMT = null,
            mainEngineConsumptionMT = null,
            inertGasGeneratorConsumptionMT = null,
            gasCombustionUnitConsumptionMT = null,
            coolingKWH = null,
            coolingSFOCGKWH = null,
            dischargeBoilerMT = null,
            dischargeKWH = null,
            dischargeSFOCGKWH = null,
            dischargeStandaloneMT = null,
            heatingBoilerMT = null,
            bdnDate = null,
            bdnFuelType = null,
            bdnNumber = null,
            bdnUtcOffset = null,
          }) =>
            new UntypedFormGroup({
              id: new UntypedFormControl(bunkerId),
              grade: new UntypedFormControl(grade),
              remainingOnBoardMT: new UntypedFormControl(
                remainingOnBoardMT,
                Validators.compose([Validators.min(0), Validators.required])
              ),
              consumptionMT: new UntypedFormControl(
                consumptionMT,
                Validators.compose([Validators.min(0), Validators.required])
              ),
              receivedMT: new UntypedFormControl(receivedMT, Validators.compose([Validators.min(0), Validators.required])),
              calculatedConsumptionMT: new UntypedFormControl(calculatedConsumptionMT),
              mainEngineConsumptionMT: new UntypedFormControl(mainEngineConsumptionMT),
              auxConsumptionMT: new UntypedFormControl(auxConsumptionMT),
              boilerConsumptionMT: new UntypedFormControl(boilerConsumptionMT),
              inertGasGeneratorConsumptionMT: new UntypedFormControl(inertGasGeneratorConsumptionMT),
              gasCombustionUnitConsumptionMT: new UntypedFormControl(gasCombustionUnitConsumptionMT),
              coolingKWH: new UntypedFormControl(coolingKWH),
              coolingSFOCGKWH: new UntypedFormControl(coolingSFOCGKWH),
              dischargeBoilerMT: new UntypedFormControl(dischargeBoilerMT),
              dischargeKWH: new UntypedFormControl(dischargeKWH),
              dischargeSFOCGKWH: new UntypedFormControl(dischargeSFOCGKWH),
              dischargeStandaloneMT: new UntypedFormControl(dischargeStandaloneMT),
              heatingBoilerMT: new UntypedFormControl(heatingBoilerMT),
              previousROB: new UntypedFormControl(previousROB),
              previousCons: new UntypedFormControl(previousCons),
              bdnDate: new UntypedFormControl(bdnDate),
              bdnFuelType: new UntypedFormControl(bdnFuelType),
              bdnNumber: new UntypedFormControl(bdnNumber),
              bdnUtcOffset: new UntypedFormControl(bdnUtcOffset),
            })
        ),
      [Validators.required]
    ),
    anomalies: new UntypedFormArray(
      anomalies.map(
        ({ anomalyTypeId, recommendation, resolved, resolvedBy, id: anomalyId }) =>
          new UntypedFormGroup({
            anomalyTypeId: new UntypedFormControl(anomalyTypeId),
            recommendation: new UntypedFormControl(recommendation),
            resolved: new UntypedFormControl(resolved),
            resolvedBy: new UntypedFormControl(resolvedBy),
            id: new UntypedFormControl(anomalyId),
          })
      )
    ),
  });

export function validateReportDate(today: Date = new Date()): ValidatorFn {
  return (control: AbstractControl): Record<string, any> | null =>
    control.value && moment(control.value).isAfter(today) ? { isFuture: true, reportDate: control.value } : null;
}
