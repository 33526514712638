import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { VoyageFormObject } from 'src/types';

export const voyageForm = (voyage?: VoyageFormObject) =>
  new UntypedFormGroup({
    ship: new UntypedFormControl(voyage?.ship || '', Validators.required),
    charterpartyDate: new UntypedFormControl(voyage?.charterpartyDate || '', Validators.required),
    idCharterer: new UntypedFormControl(voyage?.idCharterer || ''),
    assignedUser: new UntypedFormControl(voyage?.assignedUser || '', Validators.required),
    companyUuid: new UntypedFormControl(voyage?.companyUuid || '', Validators.required),
    type: new UntypedFormControl(voyage?.type || '', Validators.required),
    ownersOption: new UntypedFormControl(voyage?.tags?.includes('oo')),
    chartererEmail: new UntypedFormControl(
      voyage?.tags?.some((tag: string) => tag?.includes('chartererEmail:'))
        ? voyage?.tags.find((tag: string) => tag?.includes('chartererEmail:')).split(':')[1]
        : ''
    ),
    masterEmail: new UntypedFormControl(
      voyage?.tags?.some((tag: string) => tag?.includes('masterEmail:'))
        ? voyage?.tags.find((tag: string) => tag?.includes('masterEmail:')).split(':')[1]
        : ''
    ),
    brokerName: new UntypedFormControl(
      voyage?.tags?.some((tag: string) => tag?.includes('brokerName:'))
        ? voyage?.tags.find((tag: string) => tag?.includes('brokerName:')).split(':')[1]
        : ''
    ),
    businessUnit: new UntypedFormControl(
      voyage?.tags?.some((tag: string) => tag?.includes('businessUnit:'))
        ? voyage?.tags.find((tag: string) => tag?.includes('businessUnit:')).split(':')[1]
        : ''
    ),
    counterPart: new UntypedFormControl(
      voyage?.tags?.some((tag: string) => tag?.includes('counterPart:'))
        ? voyage?.tags.find((tag: string) => tag?.includes('counterPart:')).split(':')[1]
        : ''
    ),
    remark: new UntypedFormControl(voyage?.remark || ''),
  });
