import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSortModule } from '@angular/material/sort';
import { MatChipsModule } from '@angular/material/chips';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDialogModule } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatPaginatorModule } from '@angular/material/paginator';

const MODULES = [
  MatTableModule,
  MatSortModule,
  MatPaginatorModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatAutocompleteModule,
  MatSlideToggleModule,
  MatTooltipModule,
  MatExpansionModule,
  MatDialogModule,
  MatIconModule,
  MatMenuModule,
  MatButtonModule,
  MatChipsModule,
  MatRadioModule,
  MatSelectModule,
  MatSidenavModule,
  MatTabsModule,
  MatCheckboxModule,
];

@NgModule({
  declarations: [],
  imports: [CommonModule, ...MODULES],
  exports: [...MODULES],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'en-GB' }],
})
export class MaterialModule {
  private path: string = '/assets/svg';
  constructor(private domSanitizer: DomSanitizer, public matIconRegistry: MatIconRegistry) {
    this.matIconRegistry
      .addSvgIcon('pin', this.setPath(`${this.path}/pin.svg`))
      .addSvgIcon('chat', this.setPath(`${this.path}/chat.svg`))
      .addSvgIcon('edit', this.setPath(`${this.path}/edit.svg`))
      .addSvgIcon('copy', this.setPath(`${this.path}/copy.svg`))
      .addSvgIcon('trash', this.setPath(`${this.path}/trash.svg`))
      .addSvgIcon('siglar', this.setPath(`${this.path}/siglar.svg`))
      .addSvgIcon('export', this.setPath(`${this.path}/export.svg`))
      .addSvgIcon('launch', this.setPath(`${this.path}/launch.svg`))
      .addSvgIcon('settings', this.setPath(`${this.path}/settings.svg`))
      .addSvgIcon('addUser', this.setPath(`${this.path}/person-add.svg`))
      .addSvgIcon('addReport', this.setPath(`${this.path}/report-add.svg`))
      .addSvgIcon('import', this.setPath(`${this.path}/report-import.svg`))
      .addSvgIcon('addComment', this.setPath(`${this.path}/comment-add.svg`))
      .addSvgIcon('dashboard', this.setPath(`${this.path}/dashboard.svg`))
      .addSvgIcon('voyage', this.setPath(`${this.path}/voyage.svg`))
      .addSvgIcon('reports', this.setPath(`${this.path}/reports.svg`))
      .addSvgIcon('company', this.setPath(`${this.path}/company.svg`))
      .addSvgIcon('view', this.setPath(`${this.path}/view.svg`))
      .addSvgIcon('ships', this.setPath(`${this.path}/links.svg`))
      .addSvgIcon('locations', this.setPath(`${this.path}/locations.svg`))
      .addSvgIcon('location-map', this.setPath(`${this.path}/location-map.svg`))
      .addSvgIcon('diamondQuestion', this.setPath(`${this.path}/diamond-question.svg`))
      .addSvgIcon('location-unavailable', this.setPath(`${this.path}/location-unavailable.svg`))
      .addSvgIcon('folder', this.setPath(`${this.path}/folder.svg`))
      .addSvgIcon('globe', this.setPath(`${this.path}/globe.svg`));
  }
  private setPath(url: string): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
